import PlayOstEngine from "./class/PlayOstEngine";
import AzuraCastConnector from "./class/connector/AzuraCastConnector";

new PlayOstEngine({
	target: '#player',
	connector: AzuraCastConnector,
	radio: {
		sse_api: "https://radio.playost.com/api/live/nowplaying/sse",
	},
	art: {
		defaultArt: '/assets/imgs/no-cover.jpg',
		checkNoArt: false
	},
	history: {
		defaultArt: '/assets/imgs/no-cover.jpg',
		checkNoArt: false
	}
}).start();