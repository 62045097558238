import AbstractBasicController from "./AbstractBasicController";
import ObjectTool from "../tool/Object";

class PopinController extends AbstractBasicController {

    /**
     * @inheritDoc
     * @param {Object} config
     */
    constructor(config = {}) {
        config = ObjectTool.deepMerge({
            closeButtonSelector: '.popin--action-close',
            containerSelector: '.popin__container',
            openerSelector: '.popin--action-open',
            popinWrapperSelector: '.popin'
        }, config);
        super(config);

        this.bindEvents();
    }

    /** Bind the controllers associated events */
    bindEvents() {
        const _self = this;

        /** Delegation event binding */
        document.addEventListener('click', e => {

            // Opening the popin
            if(e.target.matches( _self.options.openerSelector ) || e.target.closest( _self.options.openerSelector )) {
                _self.togglePopin( e.target.closest(_self.options.popinWrapperSelector), true );
                return;
            }

            // Closing button
            if(e.target.matches( _self.options.closeButtonSelector ) || e.target.closest( _self.options.closeButtonSelector )) {
                _self.togglePopin( e.target.closest(_self.options.popinWrapperSelector), false );
                return;
            }

            // Prevent auto-closing
            const domContainer = e.target.closest(_self.options.containerSelector);
            if(domContainer) {
                return;
            }

            // Global closing
            const domPopin = e.target.closest(_self.options.popinWrapperSelector);
            if(domPopin) {
                _self.togglePopin(domPopin, false);
            }
        }, true);
    }

    /**
     * Toggle the state of the popin
     * @param {HTMLElement} popin
     * @param {boolean} state
     */
    togglePopin(popin, state) {
        popin.querySelector(this.options.containerSelector).setAttribute('aria-hidden', !state ? 'true' : 'false');
        popin.querySelector(this.options.openerSelector).setAttribute('aria-expanded', state ? 'true' : 'false');
        popin.classList.toggle('open', state);
    }
}

export default PopinController;
