import ObjectTool from "../tool/Object";
import PlayerProgressController from "./Player/PlayerProgressController";
import PlayerControlsController from "./Player/PlayerControlsController";
import PlayerVolumeController from "./Player/PlayerVolumeController";
import AbstractBasicController from "./AbstractBasicController";

class PlayerController extends AbstractBasicController {

    /** @var {HTMLElement} DomPlayerElement */
    static DomPlayerElement;

    /** @var {PlayerProgressController} playerProgressController */
    playerProgressController;

    /** @var {PlayerControlsController} playerControlsController */
    playerControlsController;

    /** @var {PlayerVolumeController} playerVolumeController */
    playerVolumeController;

    /**
     * Self constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        config = ObjectTool.deepMerge({
            selectors: {
                player: '.player__audio',
            },
            playerControls: { selectors: {} },
            playerVolume: { selectors: {} },
            playerProgress: {},
        }, config);
        super(config);

        // Fetch the player DOM Element
        PlayerController.DomPlayerElement = document.querySelector(this.options.selectors.player);

        // Instantiating sub-controllers
        this.playerProgressController = new PlayerProgressController( this.options.playerProgress );
        this.playerControlsController = new PlayerControlsController( this.options.playerControls, PlayerController.DomPlayerElement );
        this.playerVolumeController = new PlayerVolumeController( this.options.playerVolume, PlayerController.DomPlayerElement );
    }
}

export default PlayerController;