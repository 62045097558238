/**
 * @typedef StationsFetchedEventData
 * @property {Station} defaultStation
 * @property {Object<string, Station>} stations
 */

import AbstractEvent from "./AbstractEvent";

class StationsFetchedEvent extends AbstractEvent {
    /** @inheritDoc */
    eventName = 'playost.station.initialized';

    /** @var {Object<string, Station>} stations */
    stations;

    /** @var {Station} stations */
    defaultStation;

    /**
     * @inheritDoc
     * @param {Object<string, Station>} stations
     * @param {Station} defaultStation
     */
    constructor(stations, defaultStation) {
        super();
        this.stations = stations;
        this.defaultStation = defaultStation;
    }

    /**
     * Extract the default station from the StationsFetchedEvent
     * @param {StationsFetchedEventData} evData
     * @return {Station|null}
     */
    static getDefaultStation(evData) {
        return evData.defaultStation || null;
    }

    /**
     * @inheritDoc
     * @param {StationsFetchedEventData} evData
     * @return {Station[]|null}
     */
    static getStations(evData) {
        return evData.stations || null;
    }

    /**
     * Generate event data for CustomEvent
     * @return {StationsFetchedEventData}
     */
    toJson() {
        return {
            defaultStation: this.defaultStation,
            stations: this.stations
        }
    }
}
export default StationsFetchedEvent;