class Station {
    /** @var {number|string} id */
    id;

    /** @var {boolean} is_public */
    is_public;

    /** @var {string} listen_url */
    listen_url;

    /** @var {number|null} bitrate */
    bitrate = null;

    /** @var {string} name */
    name;

    /** @var {string} shortcode */
    shortcode;

    /** @var {string} timezone */
    timezone;
}
export default Station;