/**
 * @typedef SongChangeEventData
 * @property {Song} song
 * @property {SseResponse} sse_response
 */

import AbstractEvent from "./AbstractEvent";

class SongChangeEvent extends AbstractEvent {
    /** @inheritDoc */
    eventName = 'playost.song.change';

    /** @var {Song} song */
    song;

    /** @var {SseResponse} sse_response */
    sse_response;

    /**
     * @inheritDoc
     * @param {Song} song
     * @param {SseResponse} sse_response
     */
    constructor(song, sse_response) {
        super();
        this.song = song;
        this.sse_response = sse_response;
    }

    /**
     * Extract the song from the SongChangeEvent
     * @param {SongChangeEventData} evData
     * @return {Song|null}
     */
    static getSong(evData) {
        return evData.song || null;
    }

    /**
     * Extract the SSE response from the SongChangeEvent
     * @param {SongChangeEventData} evData
     * @return {SseResponse|null}
     */
    static getSseResponse(evData) {
        return evData.sse_response || null;
    }

    /**
     * @inheritDoc
     * @return {SongChangeEventData}
     */
    toJson() {
        return {
            song: this.song,
            sse_response: this.sse_response
        }
    }
}
export default SongChangeEvent;