/**
 * Base abstract CustomEvent class for PlayOST
 */
class AbstractEvent {
    /** @var {string|null} eventName The custom event's name */
    eventName = null;

    /**
     * Self factory
     * @return {AbstractEvent}
     */
    static getInstance() {
        return new this();
    }

    /**
     * self.eventName getter
     * @return string
     */
    getEventName() {
        return this.eventName;
    }

    /** Generate event data for CustomEvent */
    toJson() {
        return {};
    }

    /**
     * Bind the given function to the current event
     * @param {Function} fn
     */
    bind(fn) {
        if(this.eventName === null) {
            throw new Error(`The event ${this.constructor.name}'s name must be set.`);
        }
        document.addEventListener( this.eventName, e => {
            fn(e.detail);
        } );
    }

    /** Dispatch the event */
    dispatch() {
        if(this.eventName === null) {
            throw new Error(`The event ${this.constructor.name}'s name must be set.`);
        }
        console.debug(`Dispatching: ${this.getEventName()}`);
        document.dispatchEvent( new CustomEvent(
            this.eventName, { detail: this.toJson() }
        ));
    }
}

export default AbstractEvent;