import StationChangeEvent from "../event/StationChangeEvent";
import StationsFetchedEvent from "../event/StationsFetchedEvent";
import AbstractBasicController from "./AbstractBasicController";
import ObjectTool from "../tool/Object";

class RadioStationListController extends AbstractBasicController{

	/** @var {Object<string, Station>} stations The list of the radio stations */
	stations = {};

	/** @var {Object<string, HTMLElement>} domStations The HTMLElement instance of each station button */
	domStations = {};

	/** @var {Station} selectedStation The current playing station */
 	selectedStation;

	/**
	 * Self constructor
	 * @param {Object} config
	 * @param {Station[]} stations
	 */
	constructor(config, stations) {
		config = ObjectTool.deepMerge({
			selectors: {
				stationsWrapper: '.player__stations',
				template: '.station__button_template'
			},
		}, config);

		super(config);

		const _self = this;
		stations.forEach(s => _self.stations[s.shortcode] = s);
		this.generateDomTemplate();
	}

	/** Generate a DOM version of the template */
	generateDomTemplate() {
		const parsedDOM = new DOMParser().parseFromString(this.dom.template.innerHTML.trim(), 'text/html');
		this.domTemplate = parsedDOM.body.firstChild.cloneNode(true);
	}

	/**
	 * Generate the buttons list for each station
	 * @return {RadioStationListController}
	 */
	generate() {
		const _self = this;
		this.dom.stationsWrapper.innerHTML = '';

		for(let k in this.stations) {
			let station = this.stations[k];
			this.domStations[ station.shortcode ] = this.domTemplate.cloneNode(true);

			this.domStations[ station.shortcode ].dataset.station = station.shortcode;

			if(this.domStations[ station.shortcode ].matches('[data-field="name"]')) {
				this.domStations[ station.shortcode ].textContent = station.name;
			} else {
				this.domStations[ station.shortcode ].querySelector('[data-field="name"]');
			}

			this.dom.stationsWrapper.append(this.domStations[ station.shortcode ]);
			this.domStations[ station.shortcode ].addEventListener('click', e => _self.onStationChange(e), false);
		}

		// If there is no default station, triggering the first one
		let aDomKeys = Object.keys(this.domStations);
		if(aDomKeys.length > 0) {
			this.selectedStation = this.stations[ aDomKeys[0] ];
			this.domStations[ aDomKeys[0] ].classList.add('active');
		}

		// Adding station shortcode to body as className
		document.body.classList.add(`station-${this.selectedStation.shortcode}`);

		// Dispatching the event
		new StationsFetchedEvent( _self.stations, _self.selectedStation).dispatch();

		return this;
	}

	/**
	 * Station change callback
	 * @param {Event} e
	 */
	onStationChange(e) {
		const _self = this;

		// First, removing the previous station className from the body
		document.body.classList.remove(`station-${this.selectedStation.shortcode}`);

		this.selectedStation = this.stations[ e.currentTarget.dataset.station ];
		e.currentTarget.parentElement.querySelectorAll('.controls__stream').forEach(el => {
			el.classList.remove('active');
		});
		e.currentTarget.classList.add('active');

		// Adding new station className to the body
		document.body.classList.add(`station-${this.selectedStation.shortcode}`);

		new StationChangeEvent(_self.selectedStation).dispatch();
	}
}

export default RadioStationListController;