import AbstractBasicController from "../AbstractBasicController";
import ObjectTool from "../../tool/Object";
import SseChangeEvent from "../../event/SseChangeEvent";

class PlayerProgressController extends AbstractBasicController {
    /** @var {number} lastInterval Define the last call of requestAnimationFrame */
    lastInterval = 0;

    /** @var {number} interval Define the period between two requestAnimationFrame callback execution */
    interval = 1000;

    /** @var {number|null} animationID RequestAnimationFrame ID */
    animationID = null;

    /** @inheritDoc */
    constructor(config = {}) {
        config = ObjectTool.deepMerge({
            selectors: {
                bar: ".player__progress__bar",
                count: ".player__progress__count",
                total: ".player__progress__total"
            }
        }, config);

        super(config);
        this.bindEvents();
    }

    /** Bind the controller's watched events */
    bindEvents() {
        const _self = this;

        SseChangeEvent.getInstance().bind(
            /** @param {SseChangeEventData} evData */
            evData => {
                _self.update( SseChangeEvent.getSseResponse(evData).nowPlaying );
            }
        );
    }

    /**
     * Update the song's progress bar
     * @param {Song} song
     */
    update(song) {
        const _self = this;

        console.debug('Chanson / Temps', {
            Nom: song.title,
            Jeu: song.album,
            "Temps brut": song.elapsed,
            "Temps Converti": this.formatTime( song.elapsed )
        });

        this.stop();
        this.animationID = requestAnimationFrame(() => _self.updateCountTime(song.elapsed, song.duration));
    }

    /**
     * Stop the current animation frame
     */
    stop() {
        if(this.animationID) {
            cancelAnimationFrame( this.animationID );
            this.animationID = null;
        }
    }

    /**
     * Format the given seconds to a time string mm:ss
     * @param {number} time
     * @return {string}
     */
    formatTime(time) {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${minutes}:${formattedSeconds}`;
    }

    /**
     * Update the time counter, based on a requestAnimationFrame
     * @param {number} time
     * @param {number} duration
     */
    updateCountTime(time, duration) {
        const _self = this;

        if(time >= duration) {
            time = 0;
        }

        if(Date.now() - this.lastInterval >= this.interval) {
            this.lastInterval = Date.now();

            this.dom.total.textContent = this.formatTime( duration );
            this.dom.count.textContent = this.formatTime( time );
            this.dom.bar.value = (time / duration)*100;

            time = time + 1;
        }

        this.animationID = requestAnimationFrame(() => _self.updateCountTime(time, duration));
    }
}
export default PlayerProgressController;