import AbstractBasicController from "../AbstractBasicController";
import ObjectTool from "../../tool/Object";
import VolumeChangeEvent from "../../event/VolumeChangeEvent";
import MuteStateChangeEvent from "../../event/MuteStateChangeEvent";

class PlayerVolumeController extends AbstractBasicController {
    /** @var {string} #localStorageVolumeProperty */
    #localStorageVolumeProperty = 'playost_volume';

    /** @var {HTMLAudioElement} dom.player */

    /**
     * @inheritDoc
     * @param {Object} config
     * @param {HTMLElement|null} domPlayer
     **/
    constructor(config = {}, domPlayer = null) {
        config = ObjectTool.deepMerge({
            selectors: {
                volumeRange: ".form-range",
                volumeButton: ".controls__volume__button"
            }
        }, config);

        super(config);

        this.dom.player = domPlayer;
        this.bindEvents();

        // Initializing the volume with stored value
        const storedVolume = this.getStoredVolume();
        this.dom.volumeRange.value = storedVolume;
        this.dom.player.volume = storedVolume / 100;
    }

    /**
     * Store the given volume value in local storage
     * @param {Number|string} value
     */
    storeVolume(value) {
        localStorage.setItem( this.#localStorageVolumeProperty, ''+value);
    }

    /**
     * Fetch the stored volume value
     * @return {Number}
     */
    getStoredVolume() {
        return parseInt(localStorage.getItem( this.#localStorageVolumeProperty ) ?? 10);
    }

    /**
     * Bind all elements events
     */
    bindEvents() {
        const _self = this;

        /** Volume change with the range input */
        this.dom.volumeRange.addEventListener('change', e => {
            _self.syncVolume(e.currentTarget.value);
        }, false);

        /** Mute toggle */
        this.dom.volumeButton.addEventListener('click', e => {
            const state = e.currentTarget.getAttribute('aria-state');
            if('muted' === state) {
                _self.calculateVolumeButtonStatus()
            } else {
                _self.calculateVolumeButtonStatus(0)
            }

            new MuteStateChangeEvent( 'muted' !== state ).dispatch();
        });

        /** Global change when the volume is changed by the range */
        VolumeChangeEvent.getInstance().bind(
            /** @param {VolumeChangeEventData} evData */
            evData => {
                const vol = VolumeChangeEvent.getVolume(evData);
                _self.calculateVolumeButtonStatus(vol);
                _self.storeVolume(vol);
                _self.dom.player.muted = false;
            }
        );
        MuteStateChangeEvent.getInstance().bind(
            /** @param {MuteStateChangeEventData} evData */
            evData => {
                _self.dom.player.muted = MuteStateChangeEvent.getMutedState(evData);
            }
        );
    }

    /**
     * Calculate the status of the volume button
     * @param {number|null} volume
     */
    calculateVolumeButtonStatus(volume = null) {
        if(null === volume) {
            volume = this.dom.volumeRange.value;
        }
        let state = null;

        if(volume === 0) {
            state = 'muted';
        } else if(volume < 50) {
            state = 'weak';
        } else {
            state = 'strong';
        }

        this.dom.volumeButton.setAttribute('aria-state', state);
    }

    /**
     * Sync the volume between every elements: range, button and player
     * @param {number|string} forcedValue
     */
    syncVolume(forcedValue = null) {
        forcedValue = parseFloat(forcedValue);

        // Else syncing the other way around
        this.dom.player.volume = forcedValue / 100;
        new VolumeChangeEvent(forcedValue).dispatch();
    }
}
export default PlayerVolumeController;