import RadioStationListController from "./controller/RadioStationListController";
import ArtController from "./controller/ArtController";
import SongInfoController from "./controller/SongInfoController";
import HistoryController from "./controller/HistoryController";
import StationsFetchedEvent from "./event/StationsFetchedEvent";
import SseChangeEvent from "./event/SseChangeEvent";
import SongChangeEvent from "./event/SongChangeEvent";
import StationChangeEvent from "./event/StationChangeEvent";
import PlayerController from "./controller/PlayerController";
import PopinController from "./controller/PopinController";
import ObjectTool from "./tool/Object";
import AnimationController from "./controller/AnimationController";

class PlayOstEngine {
	/** Define engine's default options */
	defaultOptions = {
		/** @var {string|null} target Main wrapper selector */
		target: null,
		/** @var {AbstractConnector|Class|null} target SSE connector class */
		connector: null,
		radio: {
			/** @var {string|null} sse_api Base URI of the radio */
			sse_api: null,
			/** @var {Station[]} stations List of the stations */
			stations: []
		},

		/** Sub-Controllers potential configs to pass on */
		art: {},
		player: {},
		history: {},
		songInfo: {},
		radioStationList: {},
		popin: {},
		animations: {},
	}

	/** Engine compiled options */
	options = {}

	/**
	 * @type {Object.<string, HTMLElement>}
	 * @property {HTMLAudioElement} player
	 **/
	dom = {}

	/** @var {AbstractConnector} stationConnector SSE Connector's instance */
	stationConnector;

	/** @var {RadioStationListController} stationsDomController */
	stationsDomController;

	/** @var {ArtController} artDomController */
	artDomController;

	/** @var {SongInfoController} songInfoController */
	songInfoController;

	/** @var {PlayerController} playerController */
	playerController;

	/** @var {HistoryController} songHistoryController */
	songHistoryController;

	/** @var {PopinController} popinController */
	popinController;

	/** @var {AnimationController} animationsController */
	animationsController;

	/** @var {Song|null} currentSong The currently played song */
	currentSong = null;

	/**
	 * Self constructor
	 * @param {Object<defaultOptions>} options
	 */
	constructor(options) {
		this.options = ObjectTool.deepMerge(this.defaultOptions, options);
	}

	/** Start the engine */
	start() {
		const _self = this;

		/** Instantiating each HTMLElement */
		this.dom.wrapper = document.querySelector( this.options.target );
		for(let t in this.options.selectors) {
			this.dom[t] = this.dom.wrapper.querySelector( this.options.selectors[t] );
		}

		// Initializing the radio connector
		this.stationConnector = new this.options.connector(this.options.radio.sse_api);


		/** Generating the standalone sub controllers */
		this.popinController = new PopinController( this.options.popin );
		this.animationsController = new AnimationController( this.options.animations );

		// Fetching stations
		this.stationConnector.getStationsList().then((stations) => {
			_self.options.radio.stations = stations;

			// Global PlayOST events
			_self.bindGlobalEvents();

			/** Generating the station-dependant sub controllers */

			// Song controllers
			_self.artDomController = new ArtController( _self.options.art );
			_self.songInfoController = new SongInfoController( _self.options.songInfo );

			// Player controller
			_self.playerController = new PlayerController( _self.options.player );

			// Song history controller
			_self.songHistoryController = new HistoryController( _self.options.history );

			// Stations button list - Note: instantiated last as it triggers an initialization event
			_self.stationsDomController = new RadioStationListController( _self.options.radioStationList, _self.options.radio.stations );
			_self.stationsDomController.generate();
		});

	}

	/**
	 * Bind all global PlayOST events
	 */
	bindGlobalEvents() {
		const _self = this;

		/** Immediately starting the handle SSE updates after station initialization */
		StationsFetchedEvent.getInstance().bind(
			/** @param {StationsFetchedEventData} evData */
			evData => {
				_self.changeStationConnector( StationsFetchedEvent.getDefaultStation(evData) );
			}
		)

		/** Changing station connector on station change */
		StationChangeEvent.getInstance().bind(
			/** @param {StationChangeEventData} evData */
			evData => {
				_self.changeStationConnector( StationChangeEvent.getStation(evData) );
			}
		);

		/** Updating interface on SSE response update */
		SseChangeEvent.getInstance().bind(
			/** @param {SseChangeEventData} evData */
			evData => {
				_self.sseEventCallback( SseChangeEvent.getSseResponse(evData) )
			}
		);
	}

	/**
	 * Trigger a station change
	 * @param {Station} station
	 */
	changeStationConnector(station) {
		const _self = this;

		if(undefined === station) {
			throw new Error("No station to play");
		}

		// If a connector is already watching, stopping it
		if( this.stationConnector ) {
			this.stationConnector.stop();
		}

		// Watching the given station flux
		this.stationConnector.watch(station);
	}

	/**
	 * Handle response from SSE event
	 * @param {SseResponse} resp
	 */
	sseEventCallback(resp) {
		if(null === this.currentSong || this.currentSong.id !== resp.nowPlaying.id) {
			this.currentSong = resp.nowPlaying;
			new SongChangeEvent( this.currentSong, resp ).dispatch();
		}
	}
}

export default PlayOstEngine;