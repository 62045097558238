import ObjectTool from "../tool/Object";
import AbstractBasicController from "./AbstractBasicController";
import ArtworkVerifier from "../tool/ArtworkVerifier";
import SongChangeEvent from "../event/SongChangeEvent";

class HistoryController extends AbstractBasicController {
    /** @var {HTMLElement} domTemplate */
    domTemplate;

    /** @inheritDoc */
    constructor(config = {}) {
        config = ObjectTool.deepMerge({
            defaultArt: null,
            checkNoArt: true,
            selectors: {
                playlistHistoryWrapper: '#playlist',
                nextSongWrapper: '.playlist__next',
                currentSongWrapper: '.playlist__current',
                previousSongListWrapper: '.playlist__previous_list',
                template: '.playlist__song_template'
            },
        }, config);

        super(config);
        this.generateDomTemplate();
        this.bindEvents();
    }

    /** Generate a DOM version of the template */
    generateDomTemplate() {
        const parsedDOM = new DOMParser().parseFromString(this.dom.template.innerHTML.trim(), 'text/html');
        this.domTemplate = parsedDOM.body.firstChild.cloneNode(true);
    }

    /** Bind all controller's events */
    bindEvents() {
        const _self = this;

        // Updating the history
        SongChangeEvent.getInstance().bind(
            /** @param {SongChangeEventData} evData */
            evData => {
                const resp = SongChangeEvent.getSseResponse(evData);
                _self.updateNextSong( resp.nextSong )
                _self.updateCurrentSong( resp.nowPlaying )
                _self.updatePreviousList( resp.history )
            }
        );
    }

    /**
     * Update the "next song" block
     * @param {Song} song
     */
    updateNextSong(song) {
        const _self = this;

        this.generateSongDomElement(song).then(domElement => {
            _self.dom.nextSongWrapper.replaceChildren(domElement);
        });
    }

    /**
     * Update the "current song" block
     * @param {Song} song
     */
    updateCurrentSong(song) {
        const _self = this;

        this.generateSongDomElement(song).then(domElement => {
            _self.dom.currentSongWrapper.replaceChildren(domElement);
        });
    }

    /**
     * Update the "previous song list" block
     * @param {Song[]} songs
     */
    updatePreviousList(songs) {
        const _self = this;
        const aNewSongs = [];

        new Promise(resolve => {
            songs.forEach((s, k) => {
                _self.generateSongDomElement(s).then(domElement => {
                    aNewSongs.push(domElement);
                    if(k === songs.length - 1) {
                        resolve(aNewSongs);
                    }
                });
            });
        }).then(aNewSongs => {
            _self.dom.previousSongListWrapper.replaceChildren(...aNewSongs);
        });
    }

    /**
     * Generate a DOM element for the given song
     * @param {Song} song
     * @return {Promise<HTMLElement>}
     */
    generateSongDomElement(song) {
        const _self = this;

        return new Promise((resolve, reject) => {
            const domSong = _self.domTemplate.cloneNode(true);

            if(_self.options.checkNoArt) {
                ArtworkVerifier.verify(song.art).then(() => {
                    _self.injectSongData(song, domSong);
                    resolve(domSong);
                }).catch(() => {
                    song.art = _self.options.defaultArt;
                    _self.injectSongData(song, domSong);
                    resolve(domSong);
                })
            } else {
                _self.injectSongData(song, domSong);
                resolve(domSong);
            }
        });
    }

    /**
     * Inject the song's data in its DOM Element
     * @param {Song} song
     * @param {HTMLElement} domElement
     */
    injectSongData(song, domElement) {
        const domArtwork = domElement.querySelector('[data-field="artwork"]');
        if(domArtwork) {
            domArtwork.src = song.art;
            domArtwork.alt = song.album;
        }

        domElement.querySelector('[data-field="album"]').textContent = song.album;
        domElement.querySelector('[data-field="title"]').textContent = song.title;
        domElement.querySelector('[data-field="artist"]').textContent = song.artist;
    }
}
export default HistoryController;