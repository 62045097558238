const ArtworkVerifier = {
    /**
     * Verify if the given URL properly exists and is not redirected or whatsoever
     * @param {string} url
     * @return {Promise<unknown>}
     */
    verify: (url) => {
        return new Promise((resolve, reject) => {
            fetch(url).then(resp => {
                resp.status === 200 ? resolve() : reject();
            }).catch(() => reject());
        });
    }
}

export default ArtworkVerifier;