/**
 * @typedef SseChangeEventData
 * @property {SseResponse} sse_response
 */

import AbstractEvent from "./AbstractEvent";

/**
 * Event triggered when the system receive an SSE update
 */
class SseChangeEvent extends AbstractEvent {
    /** @inheritDoc */
    eventName = 'playost.sse.response';

    /** @var {SseResponse} sse_response */
    sse_response;

    /**
     * @inheritDoc
     * @param {SseResponse} resp
     */
    constructor(resp) {
        super();
        this.sse_response = resp;
    }

    /**
     * Extract the SSE Response from the SseChangeEvent
     * @param {SseChangeEventData} evData
     * @return {SseResponse|null}
     */
    static getSseResponse(evData) {
        return evData.sse_response || null;
    }

    /**
     * @inheritDoc
     * @return {SseChangeEventData}
     */
    toJson() {
        return {
            sse_response: this.sse_response
        }
    }
}

export default SseChangeEvent