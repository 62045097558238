class AbstractBasicController {
    /** @var {Object} options The controller's options */
    options = {}

    /** @var {Object.<string, HTMLElement>} dom The HTMLElement instance of each sub elements */
    dom = {}

    /**
     * Self constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        this.options = {...this.options, ...config};

        if(this.options.selectors) {
            for(let el in this.options.selectors) {
                this.dom[ el ] = document.querySelector( this.options.selectors[ el ] );
            }
        }
    }
}
export default AbstractBasicController;