import SseChangeEvent from "../event/SseChangeEvent";

class AbstractConnector {

	/** @var {string} uri SSE URI of the AzuraCast server */
	uri;

	/** @var {Station|null} station The station's slug */
	station;

	/** @var {EventSource} eventSource The EventSource instance for the SSE connection */
	eventSource;

	/**
	 * Self constructor
	 * @param {string} uri
	 */
	constructor(uri) {
		if (new.target === AbstractConnector) {
			throw new Error("AbstractConnector is abstract and cannot be instantiated");
		}

		this.uri = uri;

		if('' === this.uri || 'string' !== typeof this.uri) {
			throw new Error('Wrong AzuraCast base URI');
		}
	}

	/**
	 * Fetch list of stations from the radio
	 * @return {Promise<Station[]>}
	 */
	getStationsList() {
		throw new Error('Class must implement the "getStationsList" method');
	}

	/**
	 * Watch the radio station
	 * @param {Station} station
	 */
	watch(station) {
		this.station = station;

		const _self = this;
		const watchParams = this.getWatchParams();
		this.eventSource = new EventSource(this.uri+(watchParams ? '?'+watchParams.toString() : ''));
		this.eventSource.onmessage = (e) => {
			if(e.data === '{}') {
				return;
			}

			const jsonData = JSON.parse(e.data);
			const handledData = _self.handleData(jsonData);

			new SseChangeEvent(handledData).dispatch();
		}
	}

	/**
	 * Stop the SSE watching of the station
	 */
	stop() {
		this.eventSource && this.eventSource.close();
		this.eventSource = undefined;
	}

	/**
	 * Generate the parameters for the SSE connection
	 * @return {URLSearchParams|null}
	 */
	getWatchParams() {
		throw new Error('Class must implement the "getWatchParams" method');
	}

	/**
	 * Format the received data in to a SseResponse entity
	 * @param {Object} data
	 * @return {SseResponse}
	 */
	handleData(data) {
		throw new Error('Class must implement the "handleData" method');
	}
}
export default AbstractConnector;