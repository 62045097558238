/**
 * @typedef StationChangeEventData
 * @property {Station} station
 */

import AbstractEvent from "./AbstractEvent";

class StationChangeEvent extends AbstractEvent {
    /** @inheritDoc */
    eventName = 'playost.station.change';

    /** @var {Station} station */
    station;

    /**
     * @inheritDoc
     * @param {Station} station
     */
    constructor(station) {
        super();
        this.station = station;
    }

    /**
     * Extract the station from a StationChangeEvent
     * @param {StationChangeEventData} evData
     * @return {Station|null}
     */
    static getStation(evData) {
        return evData.station || null;
    }

    /**
     * @inheritDoc
     * @return {StationChangeEventData}
     */
    toJson() {
        return {
            station: this.station
        }
    }
}
export default StationChangeEvent;