/**
 * @typedef MuteStateChangeEventData
 * @property {Object} player
 * @property {boolean} player.muted
 */

import AbstractEvent from "./AbstractEvent";

class MuteStateChangeEvent extends AbstractEvent {
    /** @inheritDoc */
    eventName = 'playost.player.mute.change';

    /** @var {boolean} state */
    state;

    /**
     * @inheritDoc
     * @param {boolean} state
     */
    constructor(state) {
        super();

        this.state = state;
    }

    /**
     * Extract the muted state from the MuteStateChangeEvent
     * @param {MuteStateChangeEventData} evData
     * @return {boolean|null}
     */
    static getMutedState(evData) {
        return evData.player.muted;
    }

    /**
     * @inheritDoc
     * @return {MuteStateChangeEventData}
     */
    toJson() {
        return {
            player: {
                muted: this.state,
            }
        }
    }
}
export default MuteStateChangeEvent;