class Song {
	/** @var {number} played_at The timestamp when the song starts playing */
	played_at;

	/** @var {number} duration The song duration (in seconds) */
	duration;

	/** @var {number|null} elapsed The song elapsed time (in seconds) */
	elapsed = null;

	/** @var {string} playlist The associated playlist */
	playlist;

	/** @var {boolean} is_request Define if the song was requested */
	is_request;

	/** @var {string} id The song's id */
	id;

	/** @var {string} art The song's artwork / jacket */
	art;

	/** @var {Array} custom_fields Potential song's custom fields */
	custom_fields = [];

	/** @var {string} title Song's title */
	title;

	/** @var {string} artist Song's artist name */
	artist;

	/** @var {string} album Song's album name */
	album;

	/** @var {string} genre Song's genre */
	genre;

	/** @var {string} lyrics Song's lyrics */
	lyrics;
}

export default Song;