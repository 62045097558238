class SseResponse {
	/** @var {Station} station */
	station;

	/** @var {Song} nowPlaying */
	nowPlaying;

	/** @var {Song} nextSong */
	nextSong;

	/** @var {Song[]} history */
	history = [];
}
export default SseResponse;