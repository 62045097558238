import AbstractBasicController from "../AbstractBasicController";
import ObjectTool from "../../tool/Object";
import PlayStateEvent from "../../event/PlayStateEvent";
import StationsFetchedEvent from "../../event/StationsFetchedEvent";
import StationChangeEvent from "../../event/StationChangeEvent";
import StopStateEvent from "../../event/StopStateEvent";

class PlayerControlsController extends AbstractBasicController {
    /** @var {boolean} playState The current playing state of the player */
    playState = false;

    /** @var {Station} station The current selected station */
    station;

    /**
     * @inheritDoc
     * @param {Object} config
     * @param {HTMLElement|null} domPlayer
     **/
    constructor(config = {}, domPlayer = null) {
        config = ObjectTool.deepMerge({
            selectors: {
                playButton: ".controls__statut",
            }
        }, config);

        super(config);

        this.dom.player = domPlayer;
        this.bindEvents();
    }

    /** Bind interaction events to each element */
    bindEvents() {
        const _self = this;
        this.dom.playButton.addEventListener('click', e => {
            const playBtn = e.currentTarget;
            const currentState = playBtn.getAttribute('aria-state');

            if('play' === currentState) {
                this.play();
            } else {
                this.stop();
            }
        }, false);


        // Station change events
        StationsFetchedEvent.getInstance().bind(
            /** @param {StationsFetchedEventData} evData */
            evData => {
                _self.setStation( StationsFetchedEvent.getDefaultStation(evData) );
            }
        );
        StationChangeEvent.getInstance().bind(
            /** @param {StationChangeEventData} evData */
            evData => {
                const oldPlayState = _self.playState;

                _self.stop();
                _self.setStation( StationChangeEvent.getStation(evData) );

                if(oldPlayState) {
                    _self.play();
                }
            }
        );
    }

    /** Restore the stream play state */
    play() {
        const _self = this;

        if(this.station) {
            this.dom.player.src = this.station.listen_url;
            this.dom.player.load();

            // setTimeout, otherwise play event is not raised normally due to the "load()" call
            setTimeout(player => {
                player.play().then(() => {
                    _self.playState = true;
                    _self.dom.playButton.setAttribute('aria-state', 'stop');
                    new PlayStateEvent(_self.station).dispatch();
                });
            }, 0, this.dom.player);
        }
    }

    /** Stop the stream (not paused) */
    stop() {
        this.dom.player.src = '';
        this.dom.player.pause();
        this.playState = false;
        this.dom.playButton.setAttribute('aria-state', 'play');
        new StopStateEvent().dispatch();

        // setTimeout, otherwise pause event is not raised normally due to the "load()" call
        setTimeout(player => {
            player.load(); // This stops the stream from downloading
        }, 0, this.dom.player);
    }

    /**
     * Change the player URL for the given station
     * @param {Station} station
     */
    setStation(station) {
        this.station = station;
    }
}
export default PlayerControlsController;