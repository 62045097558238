import AbstractBasicController from "./AbstractBasicController";
import ArtworkVerifier from "../tool/ArtworkVerifier";
import ObjectTool from "../tool/Object";
import ColorThief from "colorthief/dist/color-thief.mjs";
import tinycolor from "tinycolor2";
import SongChangeEvent from "../event/SongChangeEvent";

class ArtController extends AbstractBasicController {
	/** @inheritDoc */
	constructor(config = {}) {
		config = ObjectTool.deepMerge({
			defaultArt: null,
			checkNoArt: true,
			paletteTolerance: 10,
			selectors: {
				artWrapper: '.player__thumb',
				coverThumb: '.cover',
				blurThumb: '.blur'
			}
		}, config);

		super(config);
		this.bindEvents();
	}

	bindEvents() {
		const _self = this;
		SongChangeEvent.getInstance().bind(
			/** @param {SongChangeEventData} evData */
			evData => {
				this.update( SongChangeEvent.getSong(evData) );
			}
		);
	}

	/**
	 * Update the artwork environment
	 * @param {Song} song
	 */
	update(song) {
		// Determining if we check the artwork first or not
		if(this.options.checkNoArt) {
			const _self = this;
			ArtworkVerifier.verify( song.art ).then(() => {
				_self.updateArtwork(song);
			}).catch(() => {
				song.art = _self.options.defaultArt;
				_self.updateArtwork(song);
			});
		} else {
			this.updateArtwork(song);
		}
	}

	/**
	 * Update the global artwork
	 * @param {Song} song
	 */
	updateArtwork(song) {
		const _self = this;

		this.dom.coverThumb.src = song.art;
		this.dom.coverThumb.alt = song.album;
		this.dom.blurThumb.src = song.art;
		this.dom.blurThumb.alt = song.album;

		// Make sure image is finished loading
		if (this.dom.coverThumb.complete) {
			this.setCssDominantColorVar(this.dom.coverThumb);
		} else {
			this.dom.coverThumb.addEventListener('load', function() {
				_self.setCssDominantColorVar(_self.dom.coverThumb);
			});
		}
	}

	/**
	 * Calculate and generate the CSS global variable for the art's dominant color
	 * @param {HTMLElement} img
	 */
	setCssDominantColorVar(img) {
		const colorThief = new ColorThief();
		let color = '0, 0, 0';
		let contrast = '255, 255, 255, 1';
		try {
			color = colorThief.getColor(img, 10);
			let aPalette = colorThief.getPalette(img, this.options.paletteTolerance, 10).map(col => {
				return `rgb(${col})`;
			});
			contrast = tinycolor.mostReadable(`rgb(${color})`, aPalette, {includeFallbackColors: true}).toRgb();
		} catch(e) {
			console.error(e.message);
		}

		const root = document.querySelector(':root');
		root.style.setProperty('--playost--art--dominant-color', `${color}`);
		root.style.setProperty('--playost--art--contrast-color', `${contrast.r}, ${contrast.g}, ${contrast.b}`);
	}
}
export default ArtController;