import AbstractBasicController from "./AbstractBasicController";
import ObjectTool from "../tool/Object";
import SongChangeEvent from "../event/SongChangeEvent";
import StationChangeEvent from "../event/StationChangeEvent";

class AnimationController extends AbstractBasicController {
    /** @var {{animation: number|null, position: number, direction: number, moving: boolean, target: HTMLElement|Element}[], timeout: any} animations */
    animations = [];

    constructor(config) {
        config = ObjectTool.deepMerge({
            animateSelector: '.scroll',
            directionChangeDelay: 2000,
            speed: 1, // in pixel
        }, config);

        super(config);
        this.bindEvents();
    }

    /** Bind this controller's associated events */
    bindEvents() {
        const _self = this;
        const resetFunc = () => {
            _self.clear().then(() => {
                // Need setTimeout to shift the flux of execution
                setTimeout(() => {
                    _self.animateScrollElements();
                });
            });
        };

        SongChangeEvent.getInstance().bind(resetFunc);
        StationChangeEvent.getInstance().bind(resetFunc);
    }

    /** Clear all the current animations */
    async clear() {
        for(let k in this.animations) {
            const anim = this.animations[k];
            if('object' === typeof anim) {
                anim.target.style.transform = null;
                cancelAnimationFrame(anim.animation);
                clearTimeout(anim.timeout);
            }
        }
        this.animations = [];
    }

    /** Bind an animation on scrollable elements */
    animateScrollElements() {
        const _self = this;
        document.querySelectorAll('.scroll').forEach((el, k) => {
            const textElement = el.querySelector('*');
            const containerWidth = el.offsetWidth;
            const textWidth = textElement.offsetWidth;

            if (textWidth > containerWidth) {
                _self.animations[k] = {
                    animation: null,
                    position: 0,
                    direction: -1,
                    moving: false,
                    target: textElement,
                    timeout: null
                };
                _self.animations[k].timeout = setTimeout((textElement, k) => {
                    _self.animations[k].moving = true;
                    _self.animations[k].animation = requestAnimationFrame(() => _self.animate(textElement, k));
                }, _self.options.directionChangeDelay, textElement, k);
            }
        });
    }

    /**
     * Animate the given element
     * @param {HTMLElement} el
     * @param {number} k
     */
    animate(el, k) {
        const _self = this;
        if(!this.animations[k].moving) {
            return;
        }
        this.animations[k].position += this.options.speed * this.animations[k].direction;


        if (this.animations[k].position <= -(el.offsetWidth - el.parentElement.offsetWidth) || this.animations[k].position >= 0) {
            this.animations[k].moving = false;
            _self.animations[k].timeout = setTimeout((el, k) => {
                _self.animations[k].direction *= -1; // Changer de direction
                _self.animations[k].moving = true;
                _self.animations[k].animation = requestAnimationFrame(() => _self.animate(el, k));
            }, 2000, el, k); // Délai de 2 secondes avant de changer de direction
        }

        el.style.transform = `translateX(${this.animations[k].position}px)`;
        this.animations[k].animation = requestAnimationFrame(() => this.animate(el, k));
    }
}
export default AnimationController;