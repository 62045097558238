/**
 * @typedef VolumeChangeEventData
 * @property {Object} player
 * @property {number} player.volume
 */

import AbstractEvent from "./AbstractEvent";

class VolumeChangeEvent extends AbstractEvent {
    /** @inheritDoc */
    eventName = 'playost.player.volume.change';

    /** @var {number} volume */
    volume;

    /**
     * @inheritDoc
     * @param {number} volume
     */
    constructor(volume) {
        super();
        this.volume = volume;
    }

    /**
     * Extract the volume value from the VolumeChangeEvent
     * @param {VolumeChangeEventData} evData
     * @return {number|null}
     */
    static getVolume(evData) {
        return evData.player.volume || null;
    }

    /**
     * @inheritDoc
     * @return {VolumeChangeEventData}
     */
    toJson() {
        return {
            player: {
                volume: this.volume,
            }
        }
    }
}
export default VolumeChangeEvent;