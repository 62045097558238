const ObjectTool = {
    /**
     * Create a deep merged object with two given object
     * @param {Object} source
     * @param {Object} target
     */
    deepMerge: (source, target) => {
        const result = { ...source };

        for (let key in target) {
            if (target.hasOwnProperty(key)) {
                if (target[key] instanceof Object && source[key] instanceof Object) {
                    result[key] = ObjectTool.deepMerge(source[key], target[key]);
                } else {
                    result[key] = target[key];
                }
            }
        }

        return result;
    }
}

export default ObjectTool;