import AbstractBasicController from "./AbstractBasicController";
import ObjectTool from "../tool/Object";
import SongChangeEvent from "../event/SongChangeEvent";

class SongInfoController extends AbstractBasicController {
    /** @inheritDoc */
    constructor(config = {}) {
        config = ObjectTool.deepMerge({
            selectors: {
                songInfoWrapper: '.player__infos',
                title: ".player__infos_title",
                album: ".player__infos_album",
                artist: ".player__infos_artist"
            }
        }, config);

        super(config);
        this.bindEvents();
    }

    /** Bind the controller's watched events */
    bindEvents() {
        const _self = this;

        SongChangeEvent.getInstance().bind(
            /** @param {SongChangeEventData} evData */
            evData => {
                _self.update( SongChangeEvent.getSong(evData) );
            }
        );
    }

    /**
     * Update the song's information
     * @param {Song} song
     */
    update(song) {
        this.dom.title.textContent = song.title;
        this.dom.album.textContent = song.album;
        this.dom.artist.textContent = song.artist;
    }
}

export default SongInfoController;