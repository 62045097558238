import AbstractConnector from "./AbstractConnector";
import SseResponse from "../entity/SseResponse";
import Song from "../entity/Song";
import Station from "../entity/Station";

class AzuraCastConnector extends AbstractConnector {
	/** @inheritDoc */
	// @TODO Hard coded URI without any link to the URI set in the constructor, need to rework this
	getStationsList() {
		const _self = this;

		return new Promise((resolve, reject) => {
			fetch('https://radio.playost.com/api/stations').then(resp => resp.json()).then(data => {
				const aStations = [];

				data.forEach(station => {
					aStations.push( _self.formatStation(station) );
				});
				resolve(aStations);
			}).catch(e =>  reject(e));
		});
	}

	/** @inheritDoc */
	getWatchParams() {
		const args = {"subs": {}};
		args.subs[`station:${this.station.shortcode}`] = {"recover": true}

		return new URLSearchParams({
			"cf_connect": JSON.stringify(args)
		});
	}

	/** @inheritDoc */
	handleData(data) {
		if ('connect' in data) {
			// Initial data is sent in the "connect" response as an array of rows similar to individual messages.
			if(Array.isArray(data.connect.subs['station:'+this.station.shortcode].publications) && data.connect.subs['station:'+this.station.shortcode].publications.length > 0) {
				return this.formatSseResponse( data.connect.subs['station:'+this.station.shortcode].publications[0].data.np )
			}
		} else if ('channel' in data) {
			return this.formatSseResponse(data.pub.data.np);
		}

		return null;
	}

	/**
	 * Convert AzuraCast SSE data into generic SseResponse
	 * @param {Object} data
	 * @return {SseResponse}
	 */
	formatSseResponse(data) {
		const _self = this;
		const oNowPlaying = new SseResponse();

		oNowPlaying.station = this.station;
		if('undefined' !== typeof data.now_playing) {
			oNowPlaying.nowPlaying = this.formatSong( data.now_playing );
		}
		if('undefined' !== typeof data.playing_next) {
			oNowPlaying.nextSong = this.formatSong( data.playing_next );
		}

		if('undefined' !== typeof data.song_history && Array.isArray(data.song_history)) {
			oNowPlaying.history = data.song_history.map(s => {
				return _self.formatSong(s);
			});
		}

		return oNowPlaying;
	}

	/**
	 * Format a received station json data from AzuraCast into a Station object
	 * @param {Object} jsonStationData
	 * @return {Station}
	 */
	formatStation(jsonStationData) {
		const oStation = new Station();

		oStation.id = jsonStationData.id;
		oStation.is_public = jsonStationData.is_public;
		oStation.listen_url = jsonStationData.listen_url;
		oStation.bitrate = jsonStationData.mounts[0].bitrate || null;
		oStation.name = jsonStationData.name;
		oStation.shortcode = jsonStationData.shortcode;
		oStation.timezone = jsonStationData.timezone;

		return oStation;
	}

	/**
	 * Format a received song json data from AzuraCast into a Song object
	 * @param {Object} jsonSongData
	 * @return {Song}
	 */
	formatSong(jsonSongData) {
		const oSong = new Song();

		oSong.played_at = jsonSongData.played_at;
		oSong.duration = jsonSongData.duration;
		oSong.elapsed = jsonSongData.elapsed || null;
		oSong.playlist = jsonSongData.playlist;
		oSong.is_request = jsonSongData.is_request;
		oSong.id = jsonSongData.song.id;
		oSong.art = jsonSongData.song.art;
		oSong.custom_fields = jsonSongData.song.custom_fields;
		oSong.title = jsonSongData.song.title;
		oSong.artist = jsonSongData.song.artist;
		oSong.album = jsonSongData.song.album;
		oSong.genre = jsonSongData.song.genre;
		oSong.lyrics = jsonSongData.song.lyrics;

		return oSong;
	}
}
export default AzuraCastConnector;